import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './Price.module.scss';
import { FormattedNumberParts } from 'react-intl';

const FormattedPrice = ({ price, currencyCode, isCurrencyShown }) => {
  if (!price) return;
  return (
    <FormattedNumberParts
      currency={currencyCode || process.env.REACT_APP_WEBSITE_CURRENCY}
      style="currency"
      value={price}
    >
      {(parts) => {
        return parts.map(({ type, value }) => {
          if (type === 'currency') return isCurrencyShown ? <span className={styles.smallSymbol}>{value}</span> : null;
          if (type === 'fraction') return parseInt(value, 10) > 0 ? value : '-';
          return value.trim();
        });
      }}
    </FormattedNumberParts>
  );
};

const Price = ({
  showDiscount,
  dataTestid,
  price,
  previousPrice,
  currency,
  classList = {},
  isCurrencyShown = true,
}) => {
  return (
    <div className={classNames(classList?.root || styles.root)} data-testid={dataTestid}>
      {!showDiscount && <FormattedPrice price={price} currencyCode={currency} isCurrencyShown={isCurrencyShown} />}
      {!!showDiscount && (
        <>
          {previousPrice && (
            <p className={classList?.oldPrice || styles.oldPrice}>
              <FormattedPrice price={previousPrice} currencyCode={currency} isCurrencyShown={isCurrencyShown} />
            </p>
          )}
          <span className={classList?.currentPrice || styles.currentPrice}>
            <FormattedPrice price={price} currencyCode={currency} isCurrencyShown={isCurrencyShown} />
          </span>
        </>
      )}
    </div>
  );
};

Price.propTypes = {
  showDiscount: PropTypes.bool,
  price: PropTypes.number,
  previousPrice: PropTypes.number,
  currency: PropTypes.string,
  showLeft: PropTypes.bool,
  className: PropTypes.any,
  dataTestid: PropTypes.any,
};

export default Price;
