import React from 'react';
import { ReactComponent as AdultContent } from '../../images/icons/erotics.svg';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const Image = ({ forbiddenAdultContent, imgLoadingAttr, image, title, isFashionView }) => {
  if (forbiddenAdultContent) return <AdultContent width={80} height={80} viewBox="0 0 16 16" />;
  if (isFashionView) return <img height="400" alt={title} loading={imgLoadingAttr} src={image} title={title} />;
  else return <img height="300" alt={title} loading={imgLoadingAttr} src={image} title={title} />;
};

const ProductImageWrapper = ({
  classList,
  adult,
  forbiddenAdultContent,
  imgLoadingAttr,
  image,
  title,
  showProductLink,
  url,
  isFashionView,
}) => {
  return (
    <div className={classNames({ [classList.adultPic]: adult, [classList.root]: !adult })}>
      <Image
        forbiddenAdultContent={forbiddenAdultContent}
        imgLoadingAttr={imgLoadingAttr}
        image={image}
        title={title}
        isFashionView={isFashionView}
      />
      {showProductLink && (
        <Link to={url} className={classList.quickView}>
          Quick view
        </Link>
      )}
    </div>
  );
};
//alias to be picked up by IDE import autocomplete
const ProductImage = ProductImageWrapper;

export default ProductImage;
